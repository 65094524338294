<template>
  <div>
    <div v-if="!loading">
      <apexchart
        type="radialBar"
        ref="radielBarChart"
        :height="height"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <div class="d-flex justify-content-between align-items-center" v-else>
      <CSpinner
        color="primary"
        style="width:4rem;height:4rem;"
      /><h1>{{ `${$t('label.loading')}...` }}</h1>
    </div>
  </div>
</template>
<script>
//computed
function chartOptions() {
  return {
    chart: {
      height: this.height,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "14px",
          },
          value: {
            offsetY: 4,
            fontSize: "12px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: (w) => {
              return this.total;
            },
          },
        },
      },
    },
    noData: {
      text: this.$t('label.noDataAvailable').toUpperCase(),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {  
        color: "#000000",
        fontSize: '14px',
        fontFamily: "Helvetica"
      }  
    },
    labels: this.labels,
    colors: this.colors,
  };
}
export default {
  name: "radialBar",
  data(){
    return {
      loading:true,
    }
  },
  beforeCreate(){
    this.loading = true;
  },
  mounted(){
    this.$nextTick(async () => {
      this.loading = false;
    });
  },
  update(){
    if(this.$refs.radielBarChart !== undefined){
      this.$refs.radielBarChart.refresh();
    }
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      required: true,
      default: "300",
    },
    colors: {
      type: Array,
      required: true,
      default: () => [],
    },
    series: {
      type: Array,
      required: true,
      default: () => [],
    },
    labels: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    chartOptions,
  },
};
</script>