<template>
  <div class="card" style="width: 100%;border-color: #4d5a70 !important;">
    <div
      class="card-header"
      :style="
        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
      "
    >
      <span>{{ `${$t("label.consignee")}S` }}</span>
    </div>
    <div class="card-body">
      <div>
        <SimpleBar
          v-if="ConsigneeJson.length!=0"
          Width="100%"
          Height="450"
          :Series="SeriesConsignee"
          :Categories="LabelConsignee"
          :Tooltip="Tooltip"
          :DataLabels="DataLabels"
          :Distributed="false"
          :Legend="true"
          :Yaxis="true"
          :Colors="['#1f4e79', '#ffbf67']"
        />
        <div v-else class="d-flex m-0 justify-content-center align-items-center" style="height: 450px; color:#e55353">
          <div>{{ $t('label.noDataAvailable').toUpperCase() }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import SimpleBar from "../../components/SimpleBar";

//Data
function data() {
  return {
  }
}

// Methods
function FormatNumber(Number) {
  if (Number) {
    return formatMilDecimal(Number);
  } else {
    return 0;
  }
}

//computed
function SeriesConsignee() {
  let Data = [
    {
      name: this.$t('label.planned'),
      data: this.ConsigneeJson.map(item => {
        return item.QuantityPlanning ?? 0;
      }),
    },
    {
      name: this.$t('label.executed'),
      data: this.ConsigneeJson.map(item => {
        return item.QuantityExecuted ?? 0;
      }),
    },
  ];
  return Data;
}

function LabelConsignee() {
  return this.ConsigneeJson.map(item => item.ClientName);
}

function Tooltip() {
  return {
    y: {
      formatter: function (Value) {
        return Number.parseFloat(Value);
      }
    }
  }
}

function DataLabels() {
  return {
    formatter: function (Value) {
      return Number.parseFloat(Value);
    }
  }
}

export default {
  name: "consignees",
  props: {
    ConsigneeJson: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    SimpleBar,
  },
  data,
  methods: {
    FormatNumber,
  },
  computed: {
    SeriesConsignee,
    LabelConsignee,
    Tooltip,
    DataLabels,
    ...mapState({
      FgContainerShip: state => state.situacionOperativaHistorico.FgContainerShip,
      FgGeneralCargo: state => state.situacionOperativaHistorico.FgGeneralCargo,
    })
  },
};
</script>
<style scoped>

</style>